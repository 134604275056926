  <div class="container register-container mt-4 mb-4">
      <div class="row">
          <div class="col-md-8 offset-md-2 col-lg-6 offset-lg-3 col-xs-12">
  
                <app-select-attendant-type *ngIf="attendantType==' '"
                    (confirmType)="processType($event)">
                </app-select-attendant-type>

                <app-me-familiar-union *ngIf="attendantType=='F'">
                </app-me-familiar-union>
    
                <app-form-attendant *ngIf="attendantType=='I'"
                    [attendant]="attendant" [questions]="attendant.questions"
                    [attendant_errors]="attendant_errors">
                </app-form-attendant>


                <ng-container *ngIf="manual&&attendantType!=' '">
                    <div class="row mt-3">
                        <div class="col-4 mt-1">
                            <div class="btn-group btn-group-toggle" ngbRadioGroup name="radioBasic" [(ngModel)]="send_mail">
                                <label ngbButtonLabel class="btn-primary">
                                    <input ngbButton type="radio" [value]="true"> Si
                                </label>
                                <label ngbButtonLabel class="btn-primary">
                                    <input ngbButton type="radio" [value]="false"> No
                                </label>
                            </div>
                        </div>
                        <div class="col-8 mt-1">
                            <label class="col-form-label">
                                Enviar correu amb l'entrada?
                            </label>
                        </div>
                        <div class="col-4 mt-1">
                            <div class="btn-group btn-group-toggle" ngbRadioGroup name="radioBasic" [(ngModel)]="show_entry">
                                <label ngbButtonLabel class="btn-primary">
                                    <input ngbButton type="radio" [value]="true"> Si
                                </label>
                                <label ngbButtonLabel class="btn-primary">
                                    <input ngbButton type="radio" [value]="false"> No
                                </label>
                            </div>
                        </div>
                        <div class="col-8 mt-1">
                            <label class="col-form-label">
                                Mostrar entrada per pantalla?
                            </label>
                        </div>
                        <div class="col-4 mt-1">
                            <div class="btn-group btn-group-toggle" ngbRadioGroup name="radioBasic" [(ngModel)]="has_paied">
                                <label ngbButtonLabel class="btn-primary">
                                    <input ngbButton type="radio" [value]="true"> Si
                                </label>
                                <label ngbButtonLabel class="btn-primary">
                                    <input ngbButton type="radio" [value]="false"> No
                                </label>
                            </div>
                        </div>
                        <div class="col-8 mt-1">
                            <label class="col-form-label">
                                Marcar com a pagat?
                            </label>
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngIf="attendantType=='I'">
                    <ng-container *ngFor="let event of events; index as index">
                        <ng-container *ngIf="event.archived=='F'">
                            <ng-container *ngIf="!event.has_sessions">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" [(ngModel)]="event.mer_assist" id="defaultCheck1">
                                    <label class="form-check-label" for="defaultCheck1">
                                        {{event.event_name}}
                                    </label>
                                </div>
                                <ng-container *ngIf="event.mer_assist&&event.have_alternative_price; index as index">
                                    <div class="form-check">
                                        <input class="form-check-input" [value]="' '" type="radio" [name]="'altPriceRadio'+index" [(ngModel)]="event.selectedAltPrice">
                                        <label class="form-check-label">
                                        Sense descompte - {{event.price}}€
                                        </label>
                                    </div>
                                    <ng-container *ngFor="let altPrice of event.altPrices">
                                        <div class="form-check">
                                            <input class="form-check-input" type="radio" [value]="altPrice.id" [name]="'altPriceRadio'+index" [(ngModel)]="event.selectedAltPrice">
                                            <label class="form-check-label">
                                                {{altPrice.description}} - {{altPrice.price}}€<br>
                                            </label>
                                        </div>
                                    </ng-container>
                                    <div class="row mt-2">
                                        <div class="col-12">
                                            <div class="input-group mb-1">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text" style="min-width: 46px;">Preu total:</span>
                                                </div>
                                                <span class="form-control text-right" style="font-weight: bold; font-size: 22px;">
                                                    {{getPrice(event)}}
                                                </span>
                                                <div class="input-group-append">
                                                    <span class="input-group-text" style="min-width: 46px;"><fa-icon [icon]="faEuroSign"></fa-icon></span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <small *ngIf="event.show_commission&&event.management_cost!=undefined&&event.management_cost>0">
                                                Inclou {{event.management_cost*1}} € de despeses de gestió
                                            </small>
                                        </div>
                                    </div>
                                </ng-container>
                            </ng-container>
                            <ng-container *ngIf="event.has_sessions">
                                <div class="jumbotron p-1 mb-0">
                                {{event.event_name}}<br>
                                <ng-container *ngFor="let session of event.sessions; index as index2">
                                    <div class="form-check ml-3">
                                        <input class="form-check-input" type="checkbox" [(ngModel)]="session.mer_assist" id="defaultCheck1">
                                        <label class="form-check-label" for="defaultCheck1">
                                            {{session.name}}
                                        </label>
                                    
                                        <ng-container *ngIf="session.mer_assist&&event.have_alternative_price">
                                            <div class="ml-3">
                                                <div class="form-check">
                                                    <input class="form-check-input" [value]="' '" type="radio" [name]="'e' + index + 's' + index2 + 'altPriceRadio0'" [(ngModel)]="session.selectedAltPrice">
                                                    <label class="form-check-label">
                                                    Sense descompte - {{event.price}}€
                                                    </label>
                                                </div>
                                                <ng-container *ngFor="let altPrice of event.altPrices; index as index3">
                                                    <div class="form-check">
                                                        <input class="form-check-input" type="radio" [value]="altPrice.id" [name]="'e' + index + 's' + index2 + 'altPriceRadio'+index3" [(ngModel)]="session.selectedAltPrice">
                                                        <label class="form-check-label">
                                                            {{altPrice.description}} - {{altPrice.price}}€<br>
                                                        </label>
                                                    </div>
                                                </ng-container>
                                            </div>
                                            <div class="row mt-2">
                                                <div class="col-12">
                                                    <div class="input-group mb-1">
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text" style="min-width: 46px;">Preu total:</span>
                                                        </div>
                                                        <span class="form-control text-right" style="font-weight: bold; font-size: 22px;">
                                                            {{getPriceS(event, session)}}
                                                        </span>
                                                        <div class="input-group-append">
                                                            <span class="input-group-text" style="min-width: 46px;"><fa-icon [icon]="faEuroSign"></fa-icon></span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-12">
                                                    <small *ngIf="event.show_commission&&event.management_cost!=undefined&&event.management_cost>0">
                                                        Inclou {{event.management_cost*1}} € de despeses de gestió
                                                    </small>
                                                </div>
                                            </div>
                                        </ng-container>
                                    </div>
                                </ng-container>
                                </div>
                            </ng-container>
                            <!-- PREU -->
                            <ng-container *ngIf="event.paid">
                                <hr>
                                <div class="row mt-2">
                                    <div class="col-12">
                                        <div class="input-group mb-1">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text" style="min-width: 46px;">Preu total:</span>
                                            </div>
                                            <span class="form-control text-right" style="font-weight: bold; font-size: 22px;">
                                                {{getPrice(event)}}
                                            </span>
                                            <div class="input-group-append">
                                                <span class="input-group-text" style="min-width: 46px;"><fa-icon [icon]="faEuroSign"></fa-icon></span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <small *ngIf="event.show_commission&&event.management_cost!=undefined&&event.management_cost>0">
                                            Inclou {{event.management_cost*1}} € de despeses de gestió
                                        </small>
                                    </div>
                                </div>
                            </ng-container>
                        </ng-container>
                    </ng-container>
                    <h1>PREU TOTAL: {{getTotalPrice()}}</h1>
                </ng-container>
                <div class="alert alert-danger mt-1" role="alert" *ngFor="let error of attendant_errors.general_error">
                    {{error | translate}}
                </div>
              <button type="button" *ngIf="attendantType=='I'" class="btn btn-c btn-primary mt-3" style="width: 100%;color: #FFF;"
                (click)="confirm()">Reservar</button>
          </div>
      </div>
  </div>
  
  <ng-template #content let-modal>
      <div class="modal-header">
          <h4 class="modal-title" id="modal-basic-title">Reserva completada amb èxit</h4>
          <button type="button" class="close" aria-label="Close" (click)="modal.close('')">
          <span aria-hidden="true">&times;</span>
          </button>
      </div>
      <div class="modal-body">
          En uns minuts rebràs les entrades per correu en cas contrari <a [href]="'mailto:'+supp_mail">contacta amb nosaltres <fa-icon [icon]="faQuestionCircle"></fa-icon></a>.
      </div>
      <div class="modal-footer">
          <button type="button" class="btn" (click)="modal.close('')">Tancar</button>
      </div>
  </ng-template>
  
  
  
  <app-back-button *ngIf="backend.apikey!=undefined&&backend.role!=undefined&&router.url.substring(0,14) != '/e/'"></app-back-button>
  