import { Component, OnInit } from '@angular/core';
import { BackendServiceService } from './services/backend-service.service';
import { Router } from '@angular/router';
import {TranslateService} from '@ngx-translate/core';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit{
  
  user;
  title = 'events';
  constructor(public backend:BackendServiceService,
              public router: Router,private translate: TranslateService){
                // this language will be used as a fallback when a translation isn't found in the current language
        translate.setDefaultLang('ca');

        // the lang to use, if the lang isn't available, it will use the current loader to get them
       translate.use(this.translate.getBrowserLang());
              }
  ngOnInit(): void {
    this.reloadTranslations()
  }
              reloadTranslations() {
                this.translate.reloadLang('es').subscribe(() => {
                    console.log('Traduccions en espanyol recarregades');
                });
            }
  showHeader(){
    return this.checkApiKey()&&this.checkRole(['R'])&&this.checkRoute();
  }

  showAdminHeader(){
    return this.checkApiKey()&&this.checkRole(['U','A'])&&this.checkRoute();
  }

  checkRole(roles: Array<string>){
    if(this.isSet(this.backend.role)){
      let validRole = false;
      roles.forEach(role => {
        if(this.backend.role==role)
          validRole = true;
      });
      return validRole;
    }
    else
      return false;
  }

  checkRoute(){
    return this.router.url.substring(0,14) != '/e/' && this.router.url.substring(0,13) != '/events_list/';
  }

  checkApiKey(){
    return this.isSet(this.backend.apikey);
  }

  isSet(value){
      return value != undefined;
  }
  getUser(u){
    this.user = u;
    console.log(this.user)
  }
}
