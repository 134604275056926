import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { BackendServiceService } from 'src/app/services/backend-service.service';
import { Event_class } from 'src/app/class/Event_class';
import { Question_class } from 'src/app/class/Question_class';
import { Response_class } from 'src/app/class/Response_class';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Router, ActivatedRoute } from '@angular/router';
import { EventContainerService } from 'src/app/services/event-container.service';
import { UserContainerService } from 'src/app/services/user-container.service';
import { Zone_class } from 'src/app/class/Zone_class';
import { DateTimeAdapter } from 'ng-pick-datetime';
import { Event_error_class } from 'src/app/class/Event_error_class';
import { Event_session_error_class } from 'src/app/class/Event_session_error_class';
import { Event_session_class } from 'src/app/class/Event_session_class';
import { LocalLoginService } from 'src/app/services/local-login.service';
import { NgbCalendar, NgbDate, NgbDateNativeAdapter } from '@ng-bootstrap/ng-bootstrap';
import { Event_alternative_price_error_class } from 'src/app/class/Event_alternative_price_error_class';
import { Event_alternative_price_class } from 'src/app/class/Event_alternative_price_class';



@Component({
  selector: 'app-create-modify-event',
  templateUrl: './create-modify-event.component.html',
  styleUrls: ['./create-modify-event.component.css']
})
export class CreateModifyEventComponent implements OnInit {
  public obj_event: Event_class;
  public zones: Array<Zone_class>;
  public event_date: Date;
  public open_date: Date;
  public close_date: Date;
  public door_open_date: Date;
  public event_error: Event_error_class;
  public dateAdapter: NgbDateNativeAdapter;
  altPricesErrors: Array<Event_alternative_price_error_class>;
  multiple_creator: boolean;
  show_capacity: boolean;
  public dateValue: Date = new Date ();
  hoveredDate: NgbDate | null = null;

  preguntesAlternatives=false;

  fromDate: NgbDate;
  toDate: NgbDate | null = null;

  sessions_errors: Array<Event_session_error_class>;
  enableZone: boolean;

  @ViewChild('filechooser',{static: false}) fileChooser!: ElementRef;

  public Editor_config = {
        fontSize: {
          options: [
              9,
              11,
              13,
              'default',
              17,
              19,
              21
          ]
        },
        fontColor: {
          colors: [
              {
                  color: 'hsl(0, 0%, 0%)',
                  label: 'Black'
              },
              {
                  color: 'hsl(0, 0%, 30%)',
                  label: 'Dim grey'
              },
              {
                  color: 'hsl(0, 0%, 60%)',
                  label: 'Grey'
              },
              {
                  color: 'hsl(0, 0%, 90%)',
                  label: 'Light grey'
              },
              {
                  color: 'hsl(0, 0%, 100%)',
                  label: 'White',
                  hasBorder: true
              },

              // ...
          ]
        },
        toolbar: [ 'fontSize', 'fontColor', 'bold', 'italic', 'bulletedList', 'numberedList', 'link', 'mediaEmbed' ]
  };
  public Editor = ClassicEditor;

  public questions: Array<Question_class>;

  constructor(public backend: BackendServiceService,
              private router: Router,
              private route: ActivatedRoute,
              public userContainer: UserContainerService,
              public dateTimeAdapter: DateTimeAdapter<any>,
              private localLogin: LocalLoginService,
              public calendar: NgbCalendar,
              private eventContainer: EventContainerService) {
    if(this.backend.apikey==undefined){
      if(this.localLogin.existsData()){
        this.localLogin.restore_to_service();
      } else {
        this.router.navigateByUrl("/");
      }
    }
    this.event_error = new Event_error_class();
    this.dateAdapter = new NgbDateNativeAdapter();
    this.dateTimeAdapter.setLocale('ca-ES');
    this.multiple_creator = this.backend.multiple_creator;
    this.show_capacity = this.multiple_creator;
    if(this.eventContainer.get()!=undefined){
      this.multiple_creator = false;
      this.obj_event = this.eventContainer.get();
      this.event_date = new Date(this.obj_event.date * 1000);
      this.open_date = new Date(this.obj_event.open_date * 1000);
      this.close_date = new Date(this.obj_event.close_date * 1000);
      this.door_open_date = new Date(this.obj_event.door_open_date * 1000);
      this.sessions_errors = new Array<Event_session_error_class>();
      this.obj_event.sessions.forEach(session => {
        session.open_date_d = new Date(session.open_date * 1000);
        session.close_date_d = new Date(session.close_date * 1000);
        this.sessions_errors.push(new Event_session_error_class());
      });
      this.altPricesErrors = new Array<Event_alternative_price_error_class>();
      this.obj_event.altPrices.forEach(alt => {
        this.altPricesErrors.push(new Event_alternative_price_error_class());
      });
      this.enableZone = this.obj_event.id_zone!=undefined;
      this.eventContainer.set(undefined);
    }
    else{
      this.obj_event = new Event_class();
      this.obj_event.organizers = this.backend.name_user;
      this.obj_event.altPrices.push(new Event_alternative_price_class());
      this.altPricesErrors = new Array<Event_alternative_price_error_class>();
      this.altPricesErrors.push(new Event_alternative_price_error_class());
      this.questions = new Array<Question_class>();
      this.obj_event.sessions.push(new Event_session_class());
      if(this.backend.role=='A' || this.backend.role=='R')
        this.obj_event.user_name = this.userContainer.get().user_name;
      else
        this.obj_event.user_name = this.backend.user;
      this.sessions_errors = new Array<Event_session_error_class>();
      this.sessions_errors.push(new Event_session_error_class());
    }
    this.getZones();
  }

  ngOnInit(): void {
  }

  ngAfterViewChecked(){
    this.wireUpFileChooser();
  }

  validate(){
    this.event_error = new Event_error_class();
    let result = true;
    if(this.isVoid(this.obj_event.event_name)&&!this.multiple_creator){
      this.event_error.event_name.push("El camp nom esdeveniment es obligatori");
      result = false;
    }
    if(this.isVoid(this.obj_event.num_tickets)){
      this.event_error.num_tickets.push("El camp nº entrades es obligatori");
      result = false;
    }
    if(this.isVoid(this.obj_event.date)){
      this.event_error.date.push("El camp data es obligatori");
      result = false;
    }
    if(this.isVoid(this.obj_event.open_date)){
      this.event_error.open_date.push("El camp data inici registre es obligatori");
      result = false;
    }
    if(this.isVoid(this.obj_event.close_date)){
      this.event_error.close_date.push("El camp data fi registre es obligatori");
      result = false;
    }
    if(this.isVoid(this.obj_event.door_open_date)){
      this.event_error.door_open_date.push("El camp data obertura es obligatori");
      result = false;
    }
    if(this.isVoid(this.obj_event.max_fu_attendants)){
      this.event_error.max_fu_attendants.push("El camp màxim inscrits per unitat familiar es obligatori");
      result = false;
    }

    if(this.multiple_creator&&this.isVoid(this.obj_event.capacity)){
      this.event_error.max_fu_attendants.push("El camp aforament es obligatori");
      result = false;
    }

    return result;
  }

  isVoid(text){
    return text == '' || text == undefined;
  }

  save(){
    if(this.multiple_creator){
      this.obj_event.mcFromDate =  this.dateAdapter.toModel(this.fromDate);
      this.obj_event.mcToDate  =  this.dateAdapter.toModel(this.toDate);
    }
    if(!this.isVoid(this.event_date))
      this.obj_event.date = Math.round(this.event_date.getTime()/1000);
    else
      this.obj_event.date = undefined;
    if(!this.isVoid(this.open_date))
      this.obj_event.open_date = Math.round(this.open_date.getTime()/1000);
    else
      this.obj_event.open_date = undefined;
    if(!this.isVoid(this.close_date))
      this.obj_event.close_date = Math.round(this.close_date.getTime()/1000);
    else
      this.obj_event.close_date = undefined;
    if(!this.isVoid(this.door_open_date))
      this.obj_event.door_open_date = Math.round(this.door_open_date.getTime()/1000);
    else
      this.obj_event.door_open_date = undefined;

    if(this.obj_event.has_sessions)
      this.obj_event.sessions.forEach(session => {
        if(!this.isVoid(session.open_date_d))
          session.open_date = Math.round(session.open_date_d.getTime()/1000);
        if(!this.isVoid(session.close_date_d))
          session.close_date = Math.round(session.close_date_d.getTime()/1000);
      });
    //console.log(this.obj_event);
    if(this.validate())
      if(this.multiple_creator){
        this.backend.saveMEvent(this.obj_event).subscribe(data => {
          //console.log(data);
          if(data['error']=='login_error'){
            this.backend.logout().subscribe(data=> {
              this.localLogin.remove(true);
              this.router.navigateByUrl("/");
            })
          }

          if(data){
            if(data['ok'])
              this.router.navigateByUrl("events-list");
          }
          else{
            //Controlled error
          }
        }, error => {
          console.log(error);
        });
      } else {
        if(this.obj_event.id != undefined && this.obj_event.id != ''){
          this.backend.modifyEvent(this.obj_event).subscribe(data => {
            //console.log(data);
            if(data['error']=='login_error'){
              this.backend.logout().subscribe(data=> {
                this.localLogin.remove(true);
                this.router.navigateByUrl("/");
              })
            }

            if(data){
              if(data['ok'])
                this.router.navigateByUrl("events-list");
            }
            else{
              //Controlled error
            }
          }, error => {
            console.log(error);
          });
        } else {
          this.backend.saveEvent(this.obj_event).subscribe(data => {
            //console.log(data);
            if(data['error']=='login_error'){
              this.backend.logout().subscribe(data=> {
                this.localLogin.remove(true);
                this.router.navigateByUrl("/");
              })
            }

            if(data){
              if(data['ok'])
                this.router.navigateByUrl("events-list");
            }
            else{
              //Controlled error
            }
          }, error => {
            console.log(error);
          });
        }
      }
  }
  saveStay(){
    if(this.multiple_creator){
      this.obj_event.mcFromDate =  this.dateAdapter.toModel(this.fromDate);
      this.obj_event.mcToDate  =  this.dateAdapter.toModel(this.toDate);
    }
    if(!this.isVoid(this.event_date))
      this.obj_event.date = Math.round(this.event_date.getTime()/1000);
    else
      this.obj_event.date = undefined;
    if(!this.isVoid(this.open_date))
      this.obj_event.open_date = Math.round(this.open_date.getTime()/1000);
    else
      this.obj_event.open_date = undefined;
    if(!this.isVoid(this.close_date))
      this.obj_event.close_date = Math.round(this.close_date.getTime()/1000);
    else
      this.obj_event.close_date = undefined;
    if(!this.isVoid(this.door_open_date))
      this.obj_event.door_open_date = Math.round(this.door_open_date.getTime()/1000);
    else
      this.obj_event.door_open_date = undefined;

    if(this.obj_event.has_sessions)
      this.obj_event.sessions.forEach(session => {
        if(!this.isVoid(session.open_date_d))
          session.open_date = Math.round(session.open_date_d.getTime()/1000);
        if(!this.isVoid(session.close_date_d))
          session.close_date = Math.round(session.close_date_d.getTime()/1000);
      });
    //console.log(this.obj_event);
    if(this.validate())
      if(this.multiple_creator){
        this.backend.saveMEvent(this.obj_event).subscribe(data => {
          //console.log(data);
          if(data['error']=='login_error'){
            this.backend.logout().subscribe(data=> {
              this.localLogin.remove(true);
            })
          }

          if(data){
            if(data['ok'])
              this.router.navigateByUrl("events-list");
          }
          else{
            //Controlled error
          }
        }, error => {
          console.log(error);
        });
      } else {
        if(this.obj_event.id != undefined && this.obj_event.id != ''){
          this.backend.modifyEvent(this.obj_event).subscribe(data => {
            //console.log(data);
            if(data['error']=='login_error'){
              this.backend.logout().subscribe(data=> {
                this.localLogin.remove(true);
                this.router.navigateByUrl("/");
              })
            }

            if(data){
              if(data['ok'])
                this.router.navigateByUrl("events-list");
            }
            else{
              //Controlled error
            }
          }, error => {
            console.log(error);
          });
        } else {
          this.backend.saveEvent(this.obj_event).subscribe(data => {
            //console.log(data);
            if(data['error']=='login_error'){
              this.backend.logout().subscribe(data=> {
                this.localLogin.remove(true);
                this.router.navigateByUrl("/");
              })
            }

            if(data){
              if(data['ok'])
                this.router.navigateByUrl("events-list");
            }
            else{
              //Controlled error
            }
          }, error => {
            console.log(error);
          });
        }
      }
  }
  removeResponse(list, i){
    list = list.splice(i,1);
  }

  removeFromList(list, i){
    list = list.splice(i,1);
  }

  removeAltPrice(i){
    this.obj_event.altPrices = this.obj_event.altPrices.splice(i,1);
    this.altPricesErrors = this.altPricesErrors.splice(i,1);
  }
  trackByFn(index, item) {
    return index;
  }
  addQuestion(){
    this.obj_event.questions.push(new Question_class());
  }
  removeQuestion(list, i){
    list = list.splice(i,1);
  }
  addResponse(list){
    list.push(new Response_class());
  }

  wireUpFileChooser(){
    const elementRef = this.fileChooser.nativeElement as HTMLInputElement;

    elementRef.addEventListener('change', (evt: any) => {
      const selectedFile = evt.target.files[0];
      var reader = new FileReader();
      reader.readAsDataURL(selectedFile);
      reader.addEventListener("loadend", (evt: any) => {
        this.obj_event.image = evt.target.result;
      });
    });
  }

  getZones(){
    this.backend.getZones().subscribe(data => {
      //console.log(data);
      if(data['error']=='login_error'){
        this.backend.logout().subscribe(data=> {
          this.localLogin.remove(true);
          this.router.navigateByUrl("/");
        })
      }

      if(data){
        this.zones = Object.assign(new Array, data);
        //console.log(this.listEvents);
      }
      else{
        //Controlled error
      }
    }, error => {
      console.log(error);
    });
  }

  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && date.after(this.fromDate)) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
  }

  isHovered(date: NgbDate) {
    return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return date.equals(this.fromDate) || (this.toDate && date.equals(this.toDate)) || this.isInside(date) || this.isHovered(date);
  }

  printrange(){
    let tmp_from_date: Date =  this.dateAdapter.toModel(this.fromDate);
    let tmp_to_date: Date =  this.dateAdapter.toModel(this.toDate);

    while(tmp_from_date <= tmp_to_date){
      console.log(tmp_from_date)
      tmp_from_date.setDate(tmp_from_date.getDate()+1);
    }
  }

  getComision(){
    if(this.obj_event.price <= 0)
      return 0;
    if(this.obj_event.price <5)
      return this.backend.commission_low;
    else
      return this.backend.commission_high;
  }

  addAltPrice(){
    this.obj_event.altPrices.push(new Event_alternative_price_class());
    this.altPricesErrors.push(new Event_alternative_price_error_class());
  }
  togglePreguntes(){
    this.preguntesAlternatives = !this.preguntesAlternatives
  }
}
