import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

//export const SVR_ADDR = 'http://localhost/events/api/';
//export const SVR_ADDR = 'http://test.mesacces.com/bk/api/';
export const SVR_ADDR = 'https://app.mesacces.com/bk/api/';
//export const SVR_ADDR = 'https://acces.oa2produccions.cat/bk/api/';
//export const SVR_ADDR = 'https://www.aigua.app/bk/api/';

//export const ENT_URL = 'http://localhost/';
//export const ENT_URL = 'http://test.mesacces.com/';
export const ENT_URL = 'https://app.mesacces.com/';
export const ENT_NAME = '+acces';
export const SUPP_MAIL = 'acces@mesacces.com';

@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ]
})
export class ConstantsModule { }
